"use strict";

import "datatables.net";
import "datatables.net-bs4";
import "datatables.net-buttons";
import "datatables.net-buttons-bs4";
import "datatables.net-responsive";
import "datatables.net-responsive-bs4";
import "datatables.net-select";
import "datatables.net-select-bs4";
import "./datatables.sorting.datetime-dayjs";
import DropdownModule from "../dropdown/dropdownModule";
import { v4 as uuidv4 } from "uuid";

/*
    Datatables dom plugin
*/
$.fn.dataTable.ext.feature.push({
    fnInit: (settings: DataTables.SettingsLegacy) => {

        const pluginSettings = settings.oInit.filtersAsModal as IFiltersAsModal;

        if (!pluginSettings || pluginSettings.filtersInModal === "never") {
            return undefined;
        }

        let modalSize = "sm";
        if (pluginSettings.modalSize) {
            modalSize = pluginSettings.modalSize;
        }

        let modalTitle = "Filter";
        if (pluginSettings.modalTitle) {
            modalTitle = pluginSettings.modalTitle;
        }

        let modalButtonText = "Luk";
        if (pluginSettings.modalButtonText) {
            modalButtonText = pluginSettings.modalButtonText;
        }

        const modalHtml = `
            <div class="modal filter-modal fade" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-${modalSize}" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn btn-default btn-icon close" data-dismiss="modal" aria-label="Close">
                                <svg class="embla-icon" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <use xlink:href="/dist/icons/sprite.symbol.svg#close"></use>
                                </svg>
                            </button>
                        <h4 class="modal-title" id="myModalLabel">
                            ${modalTitle}
                        </h4>
                        </div>
                        <div class="modal-body">
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">
                                ${modalButtonText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            `;

        return $(modalHtml)[0];
    },
    cFeature: "M"
});

/**
 * Table module options
 */
export interface ITableModuleOptions {

    /**
     * Selector or HTML element for the table you want to initialize
     */
    tableSelector: string | HTMLElement;

    /**
     * Optional: Which day.js format to use for datetimes, if any. It is possible to add multiple by supplying an array. Default is [ "DD/MM/YYYY", "DD/MM/YYYY HH:mm" ].
     */
    datetimeFormat?: string[] | string;

    /**
     * Whether to enable paging or not. Default is true.
     */
    paging?: boolean;

    /**
     * Whether to enable lengthChange or not. Default is false.
     */
    lengthChange?: boolean;

    /**
     * Optional: Override page length options. Select2 is used by default
     */
    pageLengthOptions?: {
        values: number[],

        /**
         * Text to show for "show all" option. Set as undefined or null to remove the option to show all.
         */
        showAllText: string,
    };

    /**
     * Optional: Select2 is used by default on filters and page length change
     */
    useSelect2?: boolean;

    /**
     * Optional: Set default page length
     */
    defaultPageLength?: number;

    /**
     * Optional: Selector or HTML element to a <h3> title element for your table, if any.
     */
    titleSelector?: string | HTMLElement;

    /**
     * Optional: Selector or HTML element to a <div> element which contains filters for your table, if any.
     */
    filterSelector?: string | HTMLElement;

    /**
     * Optional: Base url to embla icons. Used for pagination arrows. Default is "/dist/icons"
     */
    iconBaseUrl?: string;

     /**
      * Optional: Override default language settings (default is danish)
      */
    language?: DataTables.LanguageSettings;

     /**
      * Optional: Responsive behavior(default is false)
      */
    responsive?: boolean;

    /**
     * Optional: This settings enables checkbox on rows the actions is supplied with HTML (default is false)
     */
    actions?: {
        actionsSelector: string | HTMLElement;
        selectedTextSingle: string;
        selectedTextPlural: string;
    };

     /**
      * Optional: Experimental, use with caution! Moves filters into a modal, and displays a filter button to toggle the modal
      */
    filtersAsModal?: IFiltersAsModal;

    /**
     * Optional: Override additional datatable settings. Does not override datatable settings defined in ITableModuleOptions.
     */
    additionalDatatableSettings?: DataTables.Settings;

    /**
     * Optional: This setting defines what is to be shown when there are no results in the table.
     */
    noResults?: {
        noResultsMessage?: string;
        noResultsIllustrationName?: string;
    }
}

type FiltersInModal = "auto" | "always" | "never";

interface IFiltersAsModal {
    /**
     * Defines if the filters should be displayed in a modal. Values are: "auto", "always" and "never".
     */
    filtersInModal: FiltersInModal;

    /**
     * Optional: Defines the breakpoint width in pixels at which the modal filter should be activated. If not provided, it will be activated below 992px (md).
     */
    breakpoint?: number | FiltersInModalBreakpoint;

    /**
     * Optional: Text of the modal title. If not provided, the text will be "Filter"
     */
    modalTitle?: string;

    /**
     * Optional: Text of the close modal button. If not provided, the text will be "Luk"
     */
    modalButtonText?: string;

    /**
     * Optional: Size of the modal. If not provided, the modal size will be "sm"
     */
    modalSize?: "default" | "sm" | "lg" | "xl";
}


export enum FiltersInModalBreakpoint {
    Xs = 0,
    Sm = 768,
    Md = 992,
    Lg = 1200
}

/**
 * Embla table modules which is based upon datatables
 */
export class TableModule {

    /**
     * Static method to hide overflow while changing the width of the table.
     */
    public static hideOverflow(): void {
        $(".embla-table-wrapper").each((index, element) => {
            $(element).addClass("hide-overflow");
        });
    }

    /**
     * Static method to show overflow when change of the width of the table is done.
     */
    public static showOverflow(): void {
        $(".embla-table-wrapper").each((index, element) => {
            $(element).removeClass("hide-overflow");
        });
    }

    /**
     * Static method to recalc size if windows doesnt change
     */
    public static resize(): void {
        $(".embla-table-wrapper table.responsive-table").each((index, element) => {
            const api = $(element).dataTable().api();
            (api as any).responsive.rebuild();
            (api as any).responsive.recalc();
        });
    }

    private datatablesApi: DataTables.Api;

    /**
     * Constructor for table, which initialises the module
     */
    constructor(options: ITableModuleOptions) {
        this.initModule(options);
    }

    public getDatatablesApi(): DataTables.Api {
        return this.datatablesApi;
    }

    /**
     * Filter the table by matching a given column's data with the given filter value
     * @param columnIndex 0 based index of the column to filter
     * @param filterValue Value to filter by
     * @param matchEntireColumn If the value should match the entire column
     * @param caseSensitive If the filter should be case sensitive
     */
    public filterColumn(columnIndex: number, filterValue: string, matchEntireColumn = true, caseSensitive = true): void {

        const column =  this.datatablesApi.columns([columnIndex]);
        column.every(() => {
            if (filterValue === undefined || filterValue === null || filterValue === "") {
                column.search("");
            } else if (matchEntireColumn === true) {
                column.search("^" + filterValue + "$", true, false, caseSensitive === false);
            } else {
                column.search("^(.*)" + filterValue + "(.*)$", true, caseSensitive === false);
            }

            column.draw();
        });
    }

    public getAllSelectedRows(): DataTables.Api {
        return this.datatablesApi.rows({ selected: true }).nodes();
    }

    private setUniqueTableUuid($tables: JQuery<HTMLElement>) {
        $tables.each((_i, table) => {
            const uuid = uuidv4();
            $(table).data("uuid", uuid);
        });
    }

    private TrySetFiltersFromState(table: DataTables.Api, filterSelector: string | HTMLElement) {
        $(filterSelector as any).find("select").each((i, element) => {
            const columnToFilterIndex = $(element).data("filter-column") as number;
            const selectedFilterValue = table.state().columns[columnToFilterIndex].search;

            if (selectedFilterValue.search) {
                $(element).children().each((y, option) => {
                    const optionValue = $(option).val() as string;
                    if (new RegExp(selectedFilterValue.search).test(optionValue)) {
                        $(element).val(optionValue);
                        $(element).trigger("change");
                    }
                });
            }
        });
    }

    private initModule(options: ITableModuleOptions) {
        const $table = $(options.tableSelector as any);

        if ($table.length === 0) {
            // eslint-disable-next-line no-console
            console.log("Couldn't find table for tablemodule selector", options.tableSelector);
            return;
        }

        this.setUniqueTableUuid($table);

        const language = this.getLanguage(options);
        const dom = this.getDom(options);

        let settings: DataTables.Settings = {
            language: language,
            dom: dom,
            autoWidth: true,
            order: [],
            paging: options.paging,
            lengthChange: options.lengthChange,
            columnDefs: this.generateColumnDef(options),
        };

        if (options.pageLengthOptions !== null && options.pageLengthOptions !== undefined && options.pageLengthOptions.values !== null && options.pageLengthOptions.values !== undefined && options.lengthChange === true) {

            const lengthValues = [...options.pageLengthOptions.values];
            const lengthText: (string | number)[] = [...options.pageLengthOptions.values];

            if (options.pageLengthOptions.showAllText) {
                lengthValues.push(-1);
                lengthText.push(options.pageLengthOptions.showAllText);
            }

            settings.lengthMenu = [lengthValues, lengthText];

        } else if (options.lengthChange === true) {
            settings.lengthMenu = [[25, 50, 100, -1], [25, 50, 100, "Alle"]];
        }

        if (options.defaultPageLength !== undefined && options.defaultPageLength !== null) {
            settings.pageLength = options.defaultPageLength;
        }

        if (options.responsive === true) {
            const responsiveLayout = this.getResponsiveSettings();
            const responsiveSetting = {
                responsive: responsiveLayout,
                columnDefs: this.generateColumnDef(options)
            };
            settings = $.extend(true, {}, settings, responsiveSetting);
            $table.width("100%");
            $table.addClass("responsive-table");
        }

        if (options.actions !== undefined && options.actions !== null) {
            const selectSettings = {
                select: {
                    style: "api",
                    selector: "td:first-child > *:not(.expand-arrow)"
                }
            };

            this.addSelectAllRowsCheckbox($table);
            settings = $.extend(true, {}, settings, selectSettings);
        }

        if (options.datetimeFormat !== undefined) {
            ($.fn as any).dataTable.dayjs(options.datetimeFormat);
        } else {
            ($.fn as any).dataTable.dayjs(["DD/MM/YYYY HH:mm", "DD/MM/YYYY"]);
        }

        $table.on("draw.dt", () => {
            this.initNoResults($table);
        });


        $table.on("preInit.dt", (event) => {
            const $tableWrapper = $(event.target).closest(".dataTables_wrapper");

            let $filterColumn;
            let $titleColumn;

            let $customFilter;
            let $customTitle;

            if (options.filterSelector !== undefined) {
                $filterColumn = $tableWrapper.find("div.filter-column");
                $customFilter = $(options.filterSelector as any);
            }
            if (options.titleSelector !== undefined) {
                $titleColumn = $tableWrapper.find("div.title-column");
                $customTitle = $(options.titleSelector as any);
            }

            if ($customFilter !== undefined) {
                $filterColumn.html($("<div>").append($customFilter).html());
                $customFilter.remove();
            }

            if ($customTitle !== undefined) {
                $titleColumn.html($("<div>").append($customTitle).html());
                $customTitle.remove();
            }

            if (options.actions !== undefined && options.actions !== null) {
                const $actionsDiv = $(options.actions.actionsSelector as any);
                $tableWrapper.find(".selected-rows-actions").append($("<div>").append($actionsDiv).html());
            }

            if (options.lengthChange === true) {
                new DropdownModule().init($tableWrapper.find(".filter-row .dataTables_length select")[0], {
                    searchable: false,
                    width: "60px",
                });
            }

            if (options.useSelect2 !== false) {
                const elements = $tableWrapper.find(".filter-row .filter-column select");
                for (let index = 0; index < elements.length; index++) {
                    const element = elements[index];
                    new DropdownModule().init(element, {
                        searchable: true,
                    });
                }
            }

            if (options.filtersAsModal !== undefined && options.filtersAsModal !== null) {
                if(options.filtersAsModal.breakpoint === undefined || options.filtersAsModal.breakpoint === null)
                    options.filtersAsModal.breakpoint = FiltersInModalBreakpoint.Md;

                if(options.filtersAsModal.filtersInModal === "auto") {
                    this.initfilterAsModalAuto($tableWrapper, options.filtersAsModal.breakpoint, options.filtersAsModal.filtersInModal, options.lengthChange);
                } else if(options.filtersAsModal.filtersInModal === "always") {
                    this.initFiltersAsModalAlways($tableWrapper, options.lengthChange);
                }
            }
        });

        if (options.additionalDatatableSettings !== undefined && options.additionalDatatableSettings !== null) {
            if (options.additionalDatatableSettings.columns && options.additionalDatatableSettings.columns.length > 0) {
                for (const column of options.additionalDatatableSettings.columns) {
                    if (column && (column.render === null || column.render === undefined)) {
                        column.render = $.fn.dataTable.render.text();
                    }
                }
            }

            if (options.additionalDatatableSettings.columnDefs && options.additionalDatatableSettings.columnDefs.length > 0) {
                for (const columnDefinition of options.additionalDatatableSettings.columnDefs) {
                    if (columnDefinition && (columnDefinition.render === null || columnDefinition.render === undefined)) {
                        columnDefinition.render = $.fn.dataTable.render.text();
                    }
                }
            }

            settings = $.extend(true, {}, settings, options.additionalDatatableSettings);
        }

        if (options.filtersAsModal) {
            settings = {
                ...settings,
                filtersAsModal: options.filtersAsModal
            } as any;
        }

        if (settings.order === undefined || (settings.order !== null && settings.order.length === 0)) {
            // Check headers for default order
            const $tableHeaders = $table.find("> thead > tr > th");

            for (let i = 0; i < $tableHeaders.length; i++) {

                const $tableHeader = $($tableHeaders[i]);

                if($tableHeader.data("default-order-asc")) {
                    settings.order = [[i, "asc"]];
                    continue;
                } else if ($tableHeader.data("default-order-desc")) {
                    settings.order = [[i, "desc"]];
                    continue;
                }
            }
        }

        this.datatablesApi = $table.DataTable(settings);

        if (options.additionalDatatableSettings !== undefined && options.additionalDatatableSettings !== null) {
            if (options.additionalDatatableSettings.stateSave === true && (options.additionalDatatableSettings.ajax === undefined || options.additionalDatatableSettings.ajax === null)) {
                this.TrySetFiltersFromState(this.datatablesApi, options.filterSelector);
            }
        }

        if (options.actions !== undefined && options.actions !== null) {
            this.initActions(options);
        }

        if (options.responsive !== undefined && options.responsive === true) {
            this.initResponsive($table);
        }
    }

    private initFiltersAsModalAlways($tableWrapper: JQuery<HTMLElement>, lengthChange: boolean) {
        const $filterButtonWrapper = this.createFilterButtonWrapper($tableWrapper);
        const $pageLengthFilter = $tableWrapper.find(".page-length");
        const $searchFilter = $tableWrapper.find(".search-column");
        const $modalFilterContainer = $tableWrapper.find(".modal-filter-container");
        const $filterRow = $tableWrapper.find(".filter-row");

        this.showFiltersInModal($modalFilterContainer, $filterRow, $pageLengthFilter, $searchFilter, $filterButtonWrapper, lengthChange);
    }

    private initfilterAsModalAuto($tableWrapper: JQuery<HTMLElement>, breakpointToSwitchAt: number, modalFiltering: FiltersInModal, lengthChange: boolean) {
        this.createFilterButtonWrapper($tableWrapper);

        this.bindWindowResize(breakpointToSwitchAt, () => this.toggleFiltersInModalAtBreakpoint($tableWrapper, breakpointToSwitchAt, lengthChange));
        this.toggleFiltersInModalAtBreakpoint($tableWrapper, breakpointToSwitchAt, lengthChange);
    }

    private createFilterButtonWrapper($tableWrapper: JQuery<HTMLElement>){
        const $filterButtonWrapper = $tableWrapper.find(".filter-button-wrapper");

        if($filterButtonWrapper === undefined || $filterButtonWrapper.length === 0) {
            this.addFilterButtonToTable($tableWrapper, $filterButtonWrapper);
            this.bindFilterButtonInTable($tableWrapper);
        }

        return $filterButtonWrapper;
    }

    private addFilterButtonToTable($tableWrapper: JQuery<HTMLElement>, $filterButtonWrapper: JQuery<HTMLElement>) {
        const $filterRow = $tableWrapper.find(".filter-row");
        const $filterButton = this.createFilterButtonHtml();

        $filterButtonWrapper = $("<div>", {
            class: "col-8 filter-button-wrapper"
        }).append($filterButton);

        $filterRow.prepend($filterButtonWrapper);
    }

    private createFilterButtonHtml() {
        const $svg = $("<svg>", {
            class: "embla-icon",
            "aria-hidden": "true",
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg"
        });

        const $use = $("<use>", {
            "xlink:href": "/dist/icons/sprite.symbol.svg#filter"
        });

        $svg.append($use);

        const $button = $("<button>", {
            type: "button",
            class: "btn btn-default btn-icon filter-modal-show"
        });

        $button.append($svg);

        return $("<div>").append($button).html();
    }

    private bindFilterButtonInTable($tableWrapper: JQuery<HTMLElement>) {
        const $filterButton = $tableWrapper.find(".filter-button-wrapper > .filter-modal-show");
        const $filterModalContainer = $tableWrapper.find(".modal-filter-container");
        const $filterModal = $filterModalContainer.find(".filter-modal");

        $filterButton.on("click", () => {
            $filterModal.modal("show");
        });
    }

    private toggleFiltersInModalAtBreakpoint($tableWrapper: JQuery<HTMLElement>, breakpointToSwitchAt: number, lengthChange: boolean) {
        const $pageLengthFilter = $tableWrapper.find(".page-length");
        const $searchFilter = $tableWrapper.find(".search-column");
        const $filterButtonWrapper = $tableWrapper.find(".filter-button-wrapper");
        const $modalFilterContainer = $tableWrapper.find(".modal-filter-container");
        const $filterRow = $tableWrapper.find(".filter-row");

        if(this.getCurrentWindowWidth() <= breakpointToSwitchAt) {
            this.showFiltersInModal($modalFilterContainer, $filterRow, $pageLengthFilter, $searchFilter, $filterButtonWrapper, lengthChange);
        } else {
            this.showFiltersInTable($modalFilterContainer, $filterRow, $pageLengthFilter, $searchFilter, $filterButtonWrapper, lengthChange);
        }
    }

    private showFiltersInModal($modalFilterContainer: JQuery<HTMLElement>, $filterRow: JQuery<HTMLElement>, $pageLengthFilter: JQuery<HTMLElement>, $searchFilter: JQuery<HTMLElement>, $filterButtonWrapper: JQuery<HTMLElement>, lengthChange: boolean) {
        const $modalBody = $modalFilterContainer.find(".modal-body");

        let $modalFiltersWrapper = $modalBody.find(".modal-filters");
        if($modalFiltersWrapper.length === 0) {
            $modalFiltersWrapper = $("<div>", { class: "modal-filters row"});
        }

        $modalBody.append($modalFiltersWrapper);

        this.moveCustomFiltersFromTableToModal(lengthChange, $filterRow, $pageLengthFilter, $modalFiltersWrapper);
        this.moveSearchFilterToModal($modalFilterContainer, $modalFiltersWrapper);

        $searchFilter.addClass("table-search-filter-no-label");
        $filterButtonWrapper.removeClass("d-none");
    }

    private moveSearchFilterToModal($modalFilterContainer: JQuery<HTMLElement>, $modalFiltersWrapper: JQuery<HTMLElement>) {
        const $modalSearch = $modalFiltersWrapper.find(".search-column");
        if($modalSearch === undefined || $modalSearch.length === 0) {
            const $modalSearchToMove = $modalFilterContainer.find(".search-column");
            $modalSearchToMove.removeClass("d-none");
            $modalFiltersWrapper.append($modalSearchToMove);
        }
    }

    private showFiltersInTable($modalFilterContainer: JQuery<HTMLElement>, $filterRow: JQuery<HTMLElement>, $pageLengthFilter: JQuery<HTMLElement>, $searchFilter: JQuery<HTMLElement>, $filterButtonWrapper: JQuery<HTMLElement>, lengthChange: boolean) {
        const $filterModal = $modalFilterContainer.find(".filter-modal");

        $filterModal.modal("hide"); // Hide modal (just in case it is open)

        this.moveCustomFiltersFromModalToTable(lengthChange, $filterRow, $pageLengthFilter, $filterModal);

        $searchFilter.removeClass("table-search-filter-no-label");
        $filterButtonWrapper.addClass("d-none");
    }

    private moveCustomFiltersFromTableToModal(lengthChange: boolean, $filterRow: JQuery<HTMLElement>, $pageLengthFilter: JQuery<HTMLElement>, $modalFiltersWrapper: JQuery<HTMLElement>) {
        this.moveCustomFilters(lengthChange, $modalFiltersWrapper, $pageLengthFilter, $filterRow);
    }

    private moveCustomFiltersFromModalToTable(lengthChange: boolean, $filterRow: JQuery<HTMLElement>, $pageLengthFilter: JQuery<HTMLElement>, $filterModal: JQuery<HTMLElement>) {
        this.moveCustomFilters(lengthChange, $filterRow, $pageLengthFilter, $filterModal);
    }

    private moveCustomFilters(lengthChange: boolean, $elementToMoveTo: JQuery<HTMLElement>, $pageLengthFilter: JQuery<HTMLElement>, $filterColumnParent: JQuery<HTMLElement>){
        if(lengthChange === true) {
            const $pageLengthExists = $elementToMoveTo.find(".page-length");
            if($pageLengthExists === undefined || $pageLengthExists.length === 0) {
                $elementToMoveTo.prepend($pageLengthFilter);
            }
        } else {
            const $filterColumnExists = $elementToMoveTo.find(".filter-column");
            if($filterColumnExists === undefined || $filterColumnExists.length === 0) {
                const $filterColumn = $filterColumnParent.find(".filter-column");
                $elementToMoveTo.prepend($filterColumn);
            }
        }
    }

    private bindWindowResize(breakpointToSwitchAt: number, onResizeCallback: () => void) {
        const currentWidth = this.getCurrentWindowWidth();
        let isCurrentWidthBelowOrEqualToBreakpoint = this.isWidthBelowOrEqualToBreakpoint(currentWidth, breakpointToSwitchAt);

        const toggleResizeFunc = () => {
            const newWidth = this.getCurrentWindowWidth();
            const isNewWidthBelowOrEqualToBreakpoint = this.isWidthBelowOrEqualToBreakpoint(newWidth, breakpointToSwitchAt);

            if ((isCurrentWidthBelowOrEqualToBreakpoint !== isNewWidthBelowOrEqualToBreakpoint)) {
                onResizeCallback();
                isCurrentWidthBelowOrEqualToBreakpoint = isNewWidthBelowOrEqualToBreakpoint;
            }
        };

        let handle = 0;
        $(window).on("resize", () => {
            handle = this.debounce(handle, () => toggleResizeFunc(), 100);
        });
    }

    private isWidthBelowOrEqualToBreakpoint(width: number, breakpointToSwitchAt: number) {
        return width <= breakpointToSwitchAt;
    }

    private getCurrentWindowWidth() {
        return $(window).width();
    }

    private debounce = (debounceHandle: number, callback: () => void, timeoutInMs: number): number => {
        if (debounceHandle !== undefined) {
            window.clearTimeout(debounceHandle);
        }

        return window.setTimeout(() => {
            callback();
        }, timeoutInMs);
    };

    private initNoResults($table: JQuery<HTMLElement>) {
        if($table.DataTable().page.info().recordsDisplay === 0) {
            $table.find("thead > tr").addClass("hide-header");
            const emblaTableWrapper = $table.closest(".embla-table-wrapper");
            emblaTableWrapper.find(".embla-pagination-wrapper").addClass("hide-pagination");
            emblaTableWrapper.addClass("no-border-top");
        } else {
            $table.find("thead > tr").removeClass("hide-header");
            const emblaTableWrapper = $table.closest(".embla-table-wrapper");
            emblaTableWrapper.find(".embla-pagination-wrapper").removeClass("hide-pagination");
            emblaTableWrapper.removeClass("no-border-top");
        }
    }

    private addSelectAllRowsCheckbox($table: JQuery<HTMLElement>) {
        const selectAllCheckboxId = "datatable-select-all-checkbox_" + $table.data("uuid");
        const ariaLabelText = "Vælg/fravælg alle rækker";

        const $th = $("<th>", {class: "checkbox-header-th"});
        const $input = $("<input>", {id: selectAllCheckboxId, class: "datatable-select-all-checkbox", type: "checkbox","aria-label": ariaLabelText});
        const $label = $("<label>", {for: selectAllCheckboxId, class: "datatable-select-all-label", html: "&nbsp;"});
        const $thInputLabel = $th.append($input).append($label);

        $table.find("> thead > tr").prepend($thInputLabel);
        $table.find("> tbody > tr").prepend($("<td>"));
    }

    private initResponsive($table: JQuery<HTMLElement>) {
        this.datatablesApi.on("draw", () => {
            this.toggleResponsiveState($table);
        });

        this.datatablesApi.on("responsive-resize", (e, datatable, columns) => {
            this.toggleResponsiveState($table, true);
        });

        this.toggleResponsiveState($table);
    }

    private toggleResponsiveState($table: JQuery<HTMLElement>, recalculateWidth?: boolean) {
        const $expandArrows = this.findAllExpandArrows($table);

        if ((this.datatablesApi as any).responsive.hasHidden()) {
            this.addResponsive($table, $expandArrows);
            if(recalculateWidth) {
                (this.datatablesApi as any).responsive.recalc(); // Recalculate the widths used by responsive after a change in the display
            }
        } else {
            this.removeResponsive($table, $expandArrows);
        }

        this.bindExpansionOnExpandArrows($expandArrows);
    }

    private bindExpansionOnExpandArrows($expandArrows: JQuery<HTMLElement>) {
        $expandArrows.on("click", (e) => {
            const expandArrow = $(e.currentTarget);
            const $tr = expandArrow.closest("tr");
            expandArrow.attr("aria-expanded", $tr.hasClass("parent") ? "false" : "true");
        });
    }

    private addResponsive($table: JQuery<HTMLElement>, $expandArrows: JQuery<HTMLElement>) {
        $table.addClass("responsive-state");
        this.showAllExpandArrows($expandArrows);
    }

    private removeResponsive($table: JQuery<HTMLElement>, $expandArrows: JQuery<HTMLElement>) {
        $table.removeClass("responsive-state");
        this.hideAllExpandArrows($expandArrows);
    }

    private hideAllExpandArrows($expandArrows: JQuery<HTMLElement>) {
        $expandArrows.attr("tabindex", "-1"); // Not focusable
        $expandArrows.addClass("hide-arrow");
    }

    private showAllExpandArrows($expandArrows: JQuery<HTMLElement>) {
        $expandArrows.attr("tabindex", "0"); // Focusable
        $expandArrows.removeClass("hide-arrow");
    }

    private findAllExpandArrows($element: JQuery<HTMLElement> | JQuery<EventTarget>) {
        return $element.find(".expand-arrow");
    }

    private getLanguage(options: ITableModuleOptions) {

        let iconBaseUrl = options.iconBaseUrl;
        if (iconBaseUrl === undefined || iconBaseUrl == null) {
            iconBaseUrl = "/dist/icons";
        }

        const defaultLanguageOptions = {
            info: "Viser _START_ til _END_ af _TOTAL_ linjer",
            infoEmpty: "&nbsp;",
            infoFiltered: " (filtreret fra _MAX_ linjer)",
            infoPostFix: "",
            lengthMenu: "Vis _MENU_",
            loadingRecords: "Henter...",
            processing: "Henter",
            search: "S&oslash;g",
            searchPlaceholder: "Evt. søgekriterier",
            emptyTable: this.createEmptyTableTemplate(options),
            zeroRecords: this.createEmptyTableTemplate(options),
            paginate: {
                first: "Første",
                last: "Sidste",
                next: "<svg class=\"embla-icon table-pagination-icon\" aria-hidden=\"true\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\"><use xlink:href=\"" + iconBaseUrl + "/sprite.symbol.svg#arrow-right\"></use></svg>",
                previous: "<svg class=\"embla-icon table-pagination-icon\" aria-hidden=\"true\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\"><use xlink:href=\"" + iconBaseUrl + "/sprite.symbol.svg#arrow-left\"></use></svg>"
            },
            aria: {
                paginate: {
                    first: "Første",
                    last: "Sidste",
                    next: "Næste",
                    previous: "Forrige"
                }
            },
            url: ""
        };

        if (options.paging === false) {
            defaultLanguageOptions.info = "&nbsp;";
            defaultLanguageOptions.infoFiltered = "Viser _TOTAL_ af _MAX_ linjer";
        }

        const extendedOptions = $.extend(true, {}, defaultLanguageOptions, options.language);

        return extendedOptions;
    }

    private createEmptyTableTemplate(options: ITableModuleOptions) {
        let noResultsMessage = "Der blev ikke fundet nogen resultater";
        let noResultsIllustrationName = "failure-mark";

        if(options.noResults !== undefined && options.noResults !== null) {
            const noResultsOption = options.noResults;
            if(noResultsOption.noResultsMessage !== undefined && noResultsOption.noResultsMessage !== null)
                noResultsMessage = noResultsOption.noResultsMessage;
            if(noResultsOption.noResultsIllustrationName !== undefined && noResultsOption.noResultsIllustrationName !== null)
                noResultsIllustrationName = noResultsOption.noResultsIllustrationName;
        }

        const $svg = $("<svg>", {
            class: "embla-illustration subtle",
            "aria-hidden": "true",
            "xmlns": "http://www.w3.org/2000/svg"
        });

        const $use = $("<use>", {
            "xlink:href": "/dist/illustrations/sprite.symbol.svg#" + noResultsIllustrationName
        });

        $svg.append($use);

        const $div = $("<div>", {
            class: "no-results-message"
        });

        const $message = $("<p>", {
            class: "large subtle margin-bottom-0",
            text: noResultsMessage
        });

        const $element = $div.append($svg).append($message);
        return this.getElementHtml($element);
    }

    private initActions(options: ITableModuleOptions) {
        if (this.datatablesApi.tables().length > 1) {
            throw Error("If using actions only one table can be used with the selector");
        }

        const selectAllCheckbox = $(this.datatablesApi.tables().nodes()).find(".datatable-select-all-checkbox");

        this.datatablesApi.on("select", ( e, dt: DataTables.Api, type, indexes ) =>  {
            $(dt.rows(indexes).nodes()).find(".datatable-row-select-checkbox").prop("checked", true);

            this.recalculateSelectAllCheckboxState(dt, selectAllCheckbox);

            this.updateActionsRow(options, dt);
        });

        this.datatablesApi.on("deselect", ( e, dt: DataTables.Api, type, indexes ) => {
            $(dt.rows(indexes).nodes()).find(".datatable-row-select-checkbox").prop("checked", false);
            selectAllCheckbox.prop("checked", false);
            this.updateActionsRow(options, dt);
        });

        this.datatablesApi.on("draw", () => {
            this.recalculateSelectAllCheckboxState(this.datatablesApi, selectAllCheckbox);

            this.updateActionsRow(options, this.datatablesApi);
        });

        selectAllCheckbox.on("click", () => {
            const checkAllIsChecked = selectAllCheckbox.is(":checked");

            if (checkAllIsChecked === true) {
                this.datatablesApi.rows({ page: "current" }).select();
            } else {
                this.datatablesApi.rows().deselect();
            }
        });

        $(this.datatablesApi.tables().nodes()).on("click", ".datatable-row-select-checkbox", (e) => {
            e.stopPropagation();

            const $tr = $(e.target).closest("tr");

            if ($tr.hasClass("selected")) {
                this.datatablesApi.row($tr).deselect();
            } else {
                this.datatablesApi.row($tr).select();
            }
        });
    }

    private updateActionsRow(options: ITableModuleOptions, datatablesApi: DataTables.Api) {
        const selectedCount = datatablesApi.rows({ selected: true }).count();
        const actionsRow = $(datatablesApi.tables().containers()).find(".actions-row");
        const cardBodyActionRow = $(datatablesApi.tables().containers()).find(".card-body-actions-row");

        if (selectedCount === 0) {
            cardBodyActionRow.removeClass("visible");
        } else if (selectedCount === 1) {
            cardBodyActionRow.addClass("visible");
            actionsRow.find(".selected-rows-text").html(selectedCount + " " + options.actions.selectedTextSingle);
        } else {
            cardBodyActionRow.addClass("visible");
            actionsRow.find(".selected-rows-text").html(selectedCount + " " + options.actions.selectedTextPlural);
        }
    }

    private getResponsiveSettings() {
        const responsive = {
            details: {
                renderer: (api: any, rowIdx: any ) => {
                    const data = api.cells(rowIdx, ":hidden").eq(0).map( ( cell: any ) => {
                        const header = $(api.column(cell.column).header());

                        return "<tr class=\"embla-responsive-tr-border\">" +
                                "<td>" +
                                    header.text() + " " +
                                "</td> " +
                                "<td>" +
                                    api.cell(cell).render("display") +
                                "</td>" +
                            "</tr>";
                    } ).toArray().join("");

                    return data ?
                        $("<table class=\"embla-responsive-child-table margin-left-l\"/>").append(data) :
                        false;
                },
                type: "column",
                target: "td.details-button > .expand-arrow"
            }
        };

        return responsive;
    }

    private getDom(options: ITableModuleOptions) {
        let dom = "";

        if (options.titleSelector !== undefined) {
            if (options.filterSelector !== undefined) {
                dom += "<'card-body' <'row title-row'<'col-sm-12 title-column no-search-column'>>>";
            } else {
                dom += "<'card-body' <'row title-row'<'col-sm-8 title-column'><'col-4 search-column'f>>>";
            }
        }

        if (options.filterSelector !== undefined && options.lengthChange === true) {
            dom += "<'card-body' <'row filter-row'<'col-sm-8 page-length'l <'filter-column'>><'col-sm-4 search-column'f>>>";
        } else if (options.filterSelector !== undefined) {
            dom += "<'card-body' <'row filter-row'<'col-sm-8 filter-column'><'col-4 search-column'f>>>";
        }

        if (options.filterSelector === undefined && options.titleSelector === undefined && options.lengthChange === true) {
            dom += "<'card-body' <'row filter-row'<'col-4 col-sm-4 col-lg-4 col-md-4 page-length'l><'col-8 search-column'f>>>";
        } else if (options.filterSelector === undefined && options.titleSelector === undefined) {
            dom += "<'card-body' <'row filter-row'<'col-12 search-column'f>>>";
        }

        if (options.filtersAsModal && (options.filtersAsModal.filtersInModal === "auto" || options.filtersAsModal.filtersInModal === "always")) {
            dom += "<'modal-filter-container' <'col-12 search-column d-none'f> M>";
        }

        if (options.actions !== undefined && options.actions !== null) {
            dom += "<'card-body card-body-actions-row' <'actions-row'<'action-column'<'selected-rows-text'><'selected-rows-actions'>>>>";
        }

        if (options.paging !== false) {
            dom += "<'row table-row' <'col-sm-12'<'embla-table-wrapper'tr<'embla-pagination-wrapper'p>>>>";
        } else {
            dom += "<'row table-row' <'col-sm-12'<'embla-table-wrapper'tr>>>";
        }

        const card = "<'card'" + dom + ">";

        return card;
    }

    private generateColumnDef(options: ITableModuleOptions) {
        if (options.responsive === true && options.actions !== undefined && options.actions !== null) {
            return ([
                {
                    className: "details-button actions-enabled",
                    targets: 0,
                    responsivePriority: 1,
                    orderable: false,
                    render: (data: any, type: any, row: any, meta: any) => this.createExpandArrowHtml() + this.createActionCheckboxHtml(options.tableSelector, meta.row)
                }
            ]);
        } else if (options.responsive === true) {
            return ([
                {
                    className: "details-button",
                    orderable: true,
                    responsivePriority: 1,
                    targets: 0,
                    render: (data: any) => this.createExpandArrowHtml() + data
                },
            ]);
        } else if (options.actions !== undefined && options.actions !== null) {
            return ([
                {
                    className: "actions-enabled",
                    orderable: false,
                    targets:   0,
                    render: (data: any, type: any, row: any, meta: any) => this.createActionCheckboxHtml(options.tableSelector, meta.row)
                }
            ]);
        }

        return undefined;
    }

    private createExpandArrowHtml() {
        const ariaLabelText = "Vis/skjul detaljer";
        const $button = $("<button>", {
            class: "expand-arrow hide-arrow",
            "tabindex": "-1",
            "aria-label": ariaLabelText,
            "aria-expanded": "false"
        });

        return this.getElementHtml($button);
    }

    private createActionCheckboxHtml(tableSelector: string | HTMLElement, rowNumber: number) {
        const rowId = "datatable-row-select-checkbox_" + $(tableSelector as any).data("uuid") + "_" + rowNumber;

        const ariaLabelText = "Vælg/fravælg række";
        const $input = $("<input>", {
            id: rowId,
            type: "checkbox",
            class: "datatable-row-select-checkbox",
            "aria-label": ariaLabelText
        });

        const $label = $("<label>", {
            for: rowId,
            class: "datatable-row-select-label",
            html: "&nbsp;"
        });

        return this.getElementHtml($input) + this.getElementHtml($label);
    }

    private getElementHtml($element: JQuery<HTMLElement>) {
        return $("<div>").append($element).html();
    }

    private recalculateSelectAllCheckboxState(api: DataTables.Api, $selectAllCheckbox: JQuery) {
        const allSelected = api.rows( { page: "current", selected: false } ).any() === false;
        $selectAllCheckbox.prop("checked", allSelected);
    }
}
