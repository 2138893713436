import { Placement, Trigger } from "bootstrap";

/**
 * Popover module options
 */
export interface IPopoverModuleOptions {
    /**
     * The HTML content to show in the content section of the popover.
     */
    htmlContent?: string;

    /**
     * The HTML content to show in the title section of the popover.
     */
    htmlTitle?: string;

    /**
     * If the popover should show HTML.
     */
    showHtml?: boolean;

    /**
     * If the popover should sanitize the title, content and template sections.
     */
    sanitizeContent?: boolean;

    /**
     * The placement of the popover.
     * Accepted values: "auto" | "top" | "bottom" | "left" | "right".
     * Default value: "right".
     */
    placement?: Placement;

    /**
     * The trigger of the popover.
     * Accepted values: "click" | "hover" | "focus" | "manual" | "click hover" | "click focus" | "hover focus" | "click hover focus".
     * Default value: "click".
     */
    trigger?: Trigger;

    /**
     * Additional CSS class for customized styling of the popover.
     */
    additionalPopoverClassName?: string;
}

/**
 * Embla popover module which is based upon bootstrap popovers
 */
export class PopoverModule {

    /**
     * Constructor for popover, which initialises the module on the element with the provided options.
     */
    constructor($element: JQuery, options?: IPopoverModuleOptions) {
        this.initModule($element, options);
    }

    private initModule($elements: JQuery, options?: IPopoverModuleOptions) {
        $elements.each((_index, element) => {
            const $element = $(element);
            const additionalPopoverClassName = options?.additionalPopoverClassName ?? "";

            $element.popover({
                html: options?.showHtml ?? $element.data("html") ?? false,
                sanitize: options?.sanitizeContent ?? true,
                placement: options?.placement ?? $element.data("placement") ?? "right",
                title: options?.htmlTitle ?? $element.data("title") ?? "",
                content: options?.htmlContent ?? $element.data("content") ?? "",
                trigger: options?.trigger ?? $element.data("trigger") ?? "click",
                template: "<div class=\"popover " + additionalPopoverClassName + "\" role=\"tooltip\"><div class=\"arrow\"></div><h3 class=\"popover-header\"></h3><div class=\"popover-body\"></div></div>"
            });
        });
    }
}

