import {
    ClickableRowsModule,
    DatetimePickerModule,
    DatetimePickerTypeEnum,
    DropdownModule,
    FileUploadModule,
    NotificationModule,
    TableModule,
    TooltipModule
} from "ditmer-embla";
import moment = require("moment");

const initializeTables = (tableselector: string, filterselector?: string) => {

    $(tableselector).each((index, tableElement) => {
        const noPagination = $(tableElement).data("no-pagination") !== undefined;
        const datatable = new TableModule({
            tableSelector: tableElement,
            filterSelector: filterselector,
            paging: !noPagination,
            responsive: true,
            additionalDatatableSettings: {
                lengthChange: true
            }
        });
    })

    const initClickableRowsModuleForDataTables = () => {
        const clickableRowsModule = new ClickableRowsModule();
        clickableRowsModule.init({
            rowSelector: "table[data-clickable-rows] tr[data-clickable-row-url]",
            linkRowAttribute: "data-clickable-row-url",
            excludeClass: "table-row-action-button"
        });
    };

    initClickableRowsModuleForDataTables();

    $("table[data-app-datatables]").DataTable().on("draw", () => {
        initClickableRowsModuleForDataTables();
    });
};

const initializeDropdowns = (selector?: string) => {
    const dropdownModule = new DropdownModule();

    if (selector) {
        dropdownModule.init(selector, {
            language: "da"
        });
    } else {
        dropdownModule.init("select:not(.no-select2)", {
            language: "da"
        });
    }

};

const initializeFileUpload = (selector?: string): FileUploadModule => {
    if (selector) {
        return new FileUploadModule(selector);
    } else {
        return new FileUploadModule(".inputfile");
    }
};

const initializeDatePicker = (selector: string, calendarType: DatetimePickerTypeEnum) => {
    $(selector).each((index, element) => {

        let allowClear = false;
        if($(element).data("allow-clear"))
            allowClear = true;

        new DatetimePickerModule(element, {
            type: calendarType,
            allowClear: allowClear
        });
    });
};

const initializeDatePickers = (includeHidden?: boolean) => {
    const hiddenSelector = includeHidden ? "" : ":not(:hidden)";

    initializeDatePicker(".datepicker" + hiddenSelector, DatetimePickerTypeEnum.Date);
    initializeDatePicker(".datetimepicker" + hiddenSelector, DatetimePickerTypeEnum.DateAndTime);
    initializeDatePicker(".timepicker" +  hiddenSelector, DatetimePickerTypeEnum.Time);
};

export const reinitializeTable = (tableselector: string, filterselector?: string) => {
    initializeTables(tableselector, filterselector);
};

export const reinitializeDropdowns = () => {
    initializeDropdowns();
};
export const initializeDropdown = (selector: string) => {
    initializeDropdowns(selector);
};

export const reinitializeDatepickers = (includeHidden?: boolean) => {
    initializeDatePickers(includeHidden);
};

export const initializeDatepicker = (selector: string) => {
    initializeDatePicker(selector, DatetimePickerTypeEnum.Date);
};

export const initializeDateAndTimepicker = (selector: string) => {
    initializeDatePicker(selector, DatetimePickerTypeEnum.DateAndTime);
};

export const initializeTimepicker = (selector: string) => {
    initializeDatePicker(selector, DatetimePickerTypeEnum.Time);
};

export const initializeTooltip = (selector: string): TooltipModule => new TooltipModule($(selector));

export const initializeFileupload = (selector?: string): FileUploadModule => initializeFileUpload(selector);

export const ignoreClickEvent = (event: JQuery.ClickEvent) => {
    if (event.currentTarget) {
        const $clickedTarget = $(event.target);

        if ($clickedTarget.hasClass("ignore-click") || ($clickedTarget.closest(".ignore-click").length > 0)) {
            return true;
        }
    }

    return false;
};

export const clickedExpandArrow = (event: JQuery.ClickEvent) => {
    if (event.currentTarget) {
        const $clickedTarget = $(event.target);

        if ($clickedTarget.hasClass("expand-arrow") || ($clickedTarget.closest(".expand-arrow").length > 0)
            || $clickedTarget.hasClass("child") || ($clickedTarget.closest(".child").length > 0)) {
            return true;
        }
    }

    return false;
};

export const initDefaultTableRowClick = ($row: JQuery<HTMLElement>, url: string) => {
    $row.addClass("clickable-row");
    $row.on("click", "td", (event) => {
        if ($(event.target).hasClass("expand-arrow") ||
            $(event.target).hasClass("datatable-row-select-label")) {
            return;
        }

        if ((event.which && (event.which === 2 || event.which === 3)) || (event.button && event.button === 4) || event.ctrlKey) { // Middle button or control
            window.open(url);
        } else {
            window.location.href = url;
        }
    });
};

export const getElementHtml = ($element: JQuery<HTMLElement>) => $("<div>").append($element).html();

export const getColumnNames = (table: HTMLElement) => {
    const $table = $(table);

    const $tableHeaders = $table.find("thead > tr > th");

    const columns: { order: number; name: string }[] = [];

    let order = 0;
    for (const tableHeader of $tableHeaders.toArray()) {
        const $tableHeader = $(tableHeader);

        let columnName = $tableHeader.data("column-name");
        if (columnName === undefined) {
            columnName = $tableHeader.text();
        }

        columns.push({
            order,
            name: columnName
        });

        order++;
    }

    return columns;
};

export interface IDataTablePostModel {
    order: {
        column: number;
        dir: string;
        columnName: string
    }[]
}

export const addColumnNamesToDataTablesOrder = (d: IDataTablePostModel, table: HTMLElement) => {
    const columnNames = getColumnNames(table);

    if (d.order && d.order.length > 0) {
        for (const columnWithOrder of d.order) {
            const columnWithName = columnNames.filter((c) => c.order === columnWithOrder.column)[0];
            columnWithOrder.columnName = columnWithName.name;
        }
    }
};

export const periodeOverlapper = (periode: { from: moment.Moment; to: moment.Moment }, toCheckAgainst: { from: moment.Moment; to: moment.Moment }) =>
    periode.from.isBefore(toCheckAgainst.to) && toCheckAgainst.from.isBefore(periode.to);

// periode.from < toCheckAgainst.to && toCheckAgainst.from < periode.to

// 29/3 00:00 < 30/3 15:00 &&

export const clearJQueryValidation = (formSelector: string) => {
    const validator = $(formSelector).validate() as JQueryValidation.Validator;

    validator.resetForm();

    $(".async-validation-message-error").each(function () {
        $(this).removeClass("field-validation-error").addClass("field-validation-valid").text("");
    });

    $(".input-validation-error").each(function () {
        $(this).removeClass("input-validation-error");
    });
};

export const clearJQueryValidationForSelect2Dropdown = ($dropdown: JQuery) => {
    $dropdown.parent().find(".async-validation-message-error")
        .removeClass("field-validation-error")
        .addClass("field-validation-valid")
        .text("");

    $dropdown.parent().find(".input-validation-error")
        .removeClass("input-validation-error");
};

export const clearJQueryValidationElementChildren = ($element: JQuery) => {
    $element.find(".async-validation-message-error")
        .removeClass("field-validation-error")
        .addClass("field-validation-valid")
        .text("");
    $element.find(".input-validation-error")
        .removeClass("input-validation-error");
};

export interface GenericColumn<TData> {
    data: (data: TData) => unknown;
    render: (columnData: unknown) => string;
    orderable?: boolean;
    className?: string;
}

export interface Column<TData, TColumnData> extends GenericColumn<TData> {
    data: (data: TData) => TColumnData;
    render: (columnData: TColumnData) => string;
}

export const initNotifications = () => {
    const successAlert = $("#alert-success").text();
    const infoAlert = $("#alert-info").text();
    const errorAlert = $("#alert-error").text();

    if (successAlert && successAlert.length > 0) {
        const successMessages = successAlert.split(";");
        for (const message of successMessages) {
            NotificationModule.showSuccessSmall(message);
        }
    }
    if (infoAlert && infoAlert.length > 0) {
        const infoMessages = infoAlert.split(";");
        for (const message of infoMessages) {
            NotificationModule.showInfoSmall(message);
        }
    }

    if (errorAlert && errorAlert.length > 0) {
        const errorMessages = errorAlert.split(";");
        for (const message of errorMessages) {
            NotificationModule.showErrorSmall(message);
        }
    }
};
