import DropdownModule from "../dropdown";
import { RichTextEditorPlugin } from "./richTextEditor-module";

export interface IMergeFieldModel {
    value: string;
    text: string;
}

export interface IMergefieldPluginOptions {
    /**
     * Optional: The tooltip text for the  button in the toolbar. If not provided, the text will be "Indsæt flettefelt"
     */
    toolbarButtonTooltipText?: string;

    /**
     * The merge fields used by the rich text editor
     */
    mergeFields: IMergeFieldModel[];

    /**
     * Optional: Size of the modal. If not provided, the modal size will be "sm"
     */
    modalSize?: "default" | "sm" | "lg" | "xl";

    /**
     * Optional: Text of the modal title. If not provided, the text will be "Indsæt felter"
     */
    modalTitle?: string;

    /**
     * Optional: Text of the modal accept button. If not provided, the text will be "Indsæt"
     */
    modalAcceptButtonText?: string;

    /**
     * Optional: Text of the modal cancel button. If not provided, the text will be "Annuller"
     */
    modalCancelButtonText?: string;

    /**
     * Optional: The Label above the dropdown with mergefields. If not provided, the text will be "Vælg flettefelt"
     */
    mergefieldsDropdownLabel?: string;

    /**
     * Optional: Placeholder text for the dropdown with mergefields. If not provided, the text will be "Vælg flettefelt"
     */
    mergefieldDropdownPlaceholder?: string;

    /**
     * Optional: Text of the use as link switch. If not provided, the text will be "Indsæt som link"
     */
    useAsLinkLabelSwitch?: string;

    /**
     * Optional: The label above the link text input field. If not provided, the text will be "Link tekst"
     */
    linkTextLabel?: string;

    /**
     * Optional: Placeholder text for the link text input field. If not provided, the text will be "Link tekst"
     */
    linkTextPlaceholder?: string;

    /**
     * Optional: Allow links, if not provided links will not be available
     */
    allowLinks?: boolean;
}


export class MergefieldPlugin {
    private $modalElement: JQuery;
    private settings: IMergefieldPluginOptions;

    /** This a plugin for the rich text editor module. It will provide you with the ability to use mergefields in the rich text editor.
     * To override the position of the botton in the toolbar of the rich text editor, use the property customToolbar (in rich text editor module) with the value "mergefields"
     */
    constructor(options: IMergefieldPluginOptions) {

        this.getMergefield = this.getMergefield.bind(this);
        this.createLinkHtml = this.createLinkHtml.bind(this);

        this.settings = options;

        // default values
        if (options.modalSize === undefined || options.modalSize === null) {
            this.settings.modalSize = "sm";
        }

        if (options.allowLinks === undefined || options.allowLinks === null) {
            this.settings.allowLinks = false;
        }

        if (options.modalTitle === undefined || options.modalTitle === null) {
            this.settings.modalTitle = "Indsæt felter";
        }

        if (options.mergefieldDropdownPlaceholder === undefined || options.mergefieldDropdownPlaceholder === null) {
            this.settings.mergefieldDropdownPlaceholder = "Vælg flettefelt";
        }

        if (options.modalAcceptButtonText === undefined || options.modalAcceptButtonText === null) {
            this.settings.modalAcceptButtonText = "Indsæt";
        }

        if (options.modalCancelButtonText === undefined || options.modalCancelButtonText === null) {
            this.settings.modalCancelButtonText = "Annuller";
        }

        if (options.mergefieldsDropdownLabel === undefined || options.mergefieldsDropdownLabel === null) {
            this.settings.mergefieldsDropdownLabel = "Vælg flettefelt";
        }

        if (options.useAsLinkLabelSwitch === undefined || options.useAsLinkLabelSwitch === null) {
            this.settings.useAsLinkLabelSwitch = "Indsæt som link";
        }

        if (options.linkTextLabel === undefined || options.linkTextLabel === null) {
            this.settings.linkTextLabel = "Link tekst";
        }

        if (options.linkTextPlaceholder === undefined || options.linkTextPlaceholder === null) {
            this.settings.linkTextPlaceholder = "Link tekst";
        }
    }

    public getPlugin(): RichTextEditorPlugin {

        const mergeFieldPlugin: RichTextEditorPlugin<IMergefieldPluginOptions> = {
            plugin: (staticEditor, settings) => (editor) => {
                this.initializeModal();

                const toolbar = editor.getUI().getToolbar();
                const customButton = this.createCustomButton();

                if (settings.toolbarButtonTooltipText === undefined || this.settings.toolbarButtonTooltipText === null) {
                    this.settings.toolbarButtonTooltipText = "Indsæt flettefelt";
                }

                const mergeFieldButtonIndex = this.addDividerAndGetMergeFieldButtonIndex(toolbar);

                toolbar.insertItem(mergeFieldButtonIndex, {
                    type: "button",
                    options: {
                        el: customButton,
                        event: "clickMergeFieldButton",
                        tooltip: this.settings.toolbarButtonTooltipText,
                    }
                });

                (editor as any).eventManager.addEventType("clickMergeFieldButton");
                (editor as any).eventManager.listen("clickMergeFieldButton", () => {
                    this.$modalElement.show();

                    this.getMergefield((value: string) => {

                        const range = editor.getRange() as Range;
                        const textObj = editor.getTextObject(range) as toastui.WwTextObject;
                        textObj.setEndBeforeRange(range);
                        textObj.replaceContent(value);

                    });
                });
            },
            options: this.settings
        };

        return mergeFieldPlugin;
    }

    private addDividerAndGetMergeFieldButtonIndex(toolbar: toastui.Toolbar) {

        const toolbarItems = toolbar.getItems();
        let mergeFieldButtonIndex = toolbar.getItems().length;
        let shouldApplyDivider = true;

        toolbarItems.forEach((item, index) => {
            const name = item.getName();

            if (name === "mergefields") {
                mergeFieldButtonIndex = index;
                shouldApplyDivider = false;
            }
        });

        const lastToolbarItem = toolbarItems[toolbarItems.length - 1];
        if (lastToolbarItem.getName() !== "divider" && shouldApplyDivider) {
            toolbar.addItem("divider");
        }
        return mergeFieldButtonIndex;
    }

    private createCustomButton() {
        const customButton = document.createElement("button");
        customButton.classList.add("rich-text-editor-custom-button");
        customButton.classList.add("rich-text-editor-merge-field-icon");
        customButton.classList.add("tui-toolbar-icons");
        return customButton;
    }

    private initializeModal() {
        this.$modalElement = this.getMergeFieldsModalHtml();
        $("body").append(this.$modalElement);

        const dropdownModule = new DropdownModule();
        dropdownModule.init(".mergefield-select");
    }

    private getMergeFieldsModalHtml(): JQuery {

        let selectHtml = `<select class="form-control mergefield-select" placeholder="${this.settings.mergefieldDropdownPlaceholder}"style="width: 100%"><option></option>`;

        this.settings.mergeFields.forEach((mergefield) => {
            selectHtml += `<option value="${mergefield.value}">${mergefield.text}</option>`;
        });

        selectHtml += "</select>";

        this.$modalElement = $(`<div class="modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div class="modal-dialog modal-${this.settings.modalSize}" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 class="modal-title">
                            ${this.settings.modalTitle}
                            </h4>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="mergefield-select">${this.settings.mergefieldsDropdownLabel}</label>

                                <br />
                                ${selectHtml}
                            </div>

                            <div class="form-group insert-as-link-container">
                                <label class="switch-container">
                                    <input type="checkbox" class="switch insert-as-link-switch" name="insert-as-link-switch" />
                                    <div class="slider"></div>
                                </label>
                                <label for="insert-as-link-switch">${this.settings.useAsLinkLabelSwitch}</label>

                                <div class="insert-as-link-input-container" style="display: none;">
                                    <label for="link-text-input">${this.settings.linkTextLabel}</label>
                                    <input name="link-text-input" type="text" class="form-control link-text-input" placeholder="${this.settings.linkTextPlaceholder}" />
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">${this.settings.modalCancelButtonText}</button>
                            <button type="button" class="btn btn-primary insert-button">${this.settings.modalAcceptButtonText}</button>
                        </div>
                    </div>
                </div>
            </div>`);

        return this.$modalElement;
    }

    private getMergefield(mergefieldSelectedCallback: (selectedMergefield: string) => void): void {

        const $modal = this.$modalElement;

        let $insertAsLinkSwitch: JQuery<HTMLElement>;
        const $insertAsLinkContainer = $modal.find(".insert-as-link-container");

        if (this.settings.allowLinks === true) {
            $insertAsLinkContainer.show();
            $insertAsLinkSwitch = $modal.find(".insert-as-link-switch");
            $insertAsLinkSwitch.off("change");
            $insertAsLinkSwitch.on("change",
                () => {

                    const isSelected = $insertAsLinkSwitch.is(":checked") === true;

                    const $insertAsLinkInputContainer = $modal.find(".insert-as-link-input-container");
                    if (isSelected) {
                        $insertAsLinkInputContainer.show();
                    } else {
                        $insertAsLinkInputContainer.hide();
                    }
                });
        } else {
            $insertAsLinkContainer.hide();
        }

        const $insertButton = $modal.find(".insert-button");
        const $dropdown = $modal.find(".mergefield-select");

        $insertButton.off("click");
        $insertButton.one("click", () => {

            let selectedMergefield = $dropdown.find("option:selected").val() as string;

            if (this.settings.allowLinks === true && $insertAsLinkSwitch.is(":checked")) {
                const $linkTextInput = $modal.find(".link-text-input");

                selectedMergefield = this.createLinkHtml(selectedMergefield, $linkTextInput.val() as string);

                $insertAsLinkSwitch.prop("checked", false).trigger("change");
                $linkTextInput.val("");
            }

            mergefieldSelectedCallback(selectedMergefield);
            $dropdown.val(null).trigger("change");
            $modal.modal("hide");
        });

        $modal.modal("show");
    }

    private createLinkHtml(link: string, text: string): string {
        return `<a href="${link}">${text}</a>`;
    }
}
